"use client";

import clsx from "clsx";
import { useLocale, useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";
import * as RadixForm from "@radix-ui/react-form";

import { useAuth } from "@/app/contexts/AuthContext";
import { Input } from "@/app/components/ui/Input";
import Button from "@/app/components/ui/Button";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { requestCode } = useAuth();
  const router = useRouter();
  const locale = useLocale();
  const t = useTranslations("login.form");

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        await requestCode(email);
        router.push(`/${locale}/verify`);
      } catch (error) {
        console.error("Error requesting code:", error);
      }
    },
    [email, requestCode, router, locale],
  );

  return (
    <RadixForm.Root
      onSubmit={handleSubmit}
      className="space-y-4 bg-canvas-200 p-4 md:p-12 md:w-3/4 rounded-lg w-full md:max-w-lg mx-auto my-8"
    >
      <Input
        name="loginEmail"
        label={t("email")}
        value={email}
        onChange={setEmail}
        size="fill"
        type="email"
        required
        errorText={t("invalid_email")}
      />
      <div className="flex flex-col items-center">
        <Button
          type="submit"
          colorScheme="black"
          variant="primary"
          disabled={isLoading}
          className={clsx(
            "w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white max-w-36",
            isLoading ? "bg-gray-400" : "bg-black hover:bg-gray-700",
            "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black",
          )}
        >
          {isLoading ? "..." : t("submit")}
        </Button>
      </div>
    </RadixForm.Root>
  );
}

export default LoginForm;
