"use client";
import { createContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { AuthProvider } from "./AuthContext";
import { ForestProvider } from "./ForestContext";

type PageContextType = {};

const PageContext = createContext<PageContextType | undefined>(undefined);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // 60 minutes
      cacheTime: 60 * 60 * 1000, // 60 minutes
    },
  },
});

export const PageProvider: React.FC<{
  children: React.ReactNode;
  isPublic: boolean;
}> = ({ children, isPublic }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider isPublic={isPublic}>
        <ForestProvider>
          <PageContext.Provider value={{}}>{children}</PageContext.Provider>
        </ForestProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};
