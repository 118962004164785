import { forwardRef, memo, useEffect, useState } from "react";
import clsx from "clsx";
import * as RadixForm from "@radix-ui/react-form";

import Typography from "./Typography";

type SizeType = "sm" | "md" | "lg" | "xl" | "fill" | `${number}px`;

type InputProps = {
  size?: SizeType;
  ref?: React.Ref<HTMLInputElement>;
  label?: string;
  icon?: JSX.Element;
  helpText?: string | JSX.Element;
  errorText?: string;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  name: string;
  type?: string;
  placeholder?: string;
};

const sizeClasses = {
  sm: "w-40",
  md: "w-60",
  lg: "w-80",
  xl: "w-96",
  fill: "w-full",
};

// Function to determine the appropriate class for the size
const getSizeClass = (size: SizeType) => {
  if (size in sizeClasses) {
    return sizeClasses[size as keyof typeof sizeClasses];
  }

  return size;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = "md",
      label,
      icon,
      helpText,
      errorText,
      disabled = false,
      readOnly = false,
      value,
      required = false,
      onChange,
      name,
      type = "text",
      placeholder = "",
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(value || "");
    const [error, setError] = useState("");

    useEffect(() => {
      // Initialize with external value prop
      setInputValue(value || "");
    }, [value]);

    const handleClear = () => {
      setInputValue("");
      setError("");
      if (onChange) onChange("");
    };

    const validateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checkValidity()) {
        setError(""); // Reset error state if validation passes
      } else {
        setError(errorText || "This field is required");
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      if (onChange) onChange(event.target.value);
      validateInput(event);
    };

    // Define class variables
    const inputWrapperClass = clsx(
      "mb-1 relative rounded-md",
      getSizeClass(size),
    );

    const inputClass = clsx(
      "border text-body-sub font-aileron text-neutral-400 rounded-lg focus:border-transparent block w-full px-4 py-2",
      getSizeClass(size),
      { "pl-10": icon },
      { "border-red-500": error },
      {
        "bg-gray-100": disabled || readOnly,
        "bg-white": !disabled && !readOnly,
      },
      {
        "border-canvas-400": !error,
        "placeholder-neutral-300": !error,
        "placeholder-brand-orange-400": error,
        "border-brand-orange-300": error,
        "focus:ring-canvas-500": !error,
        "focus:ring-brand-orange-300": error,
        "pl-10": icon,
        "bg-brand-orange-100": error,
        "bg-gray-100": !error && !readOnly,
        "bg-canvas-300": readOnly,
      },
    );

    const asteriskClassNames = clsx(
      "font-aileron text-neutral-400 vertical-super",
      { error: "text-brand-orange-300" },
    );

    const clearButtonClass = clsx(
      "absolute inset-y-0 right-0 pr-3 flex items-center bg-red text-neutral-200 font-aileron text-body-xs",
    );

    return (
      <RadixForm.FormField name={name}>
        {label && (
          <RadixForm.Label className="block mb-2 text-neutral-500">
            <Typography.Heading size="xs" color="inherit" component="span">
              {label}
              {required && <span className={asteriskClassNames}>&nbsp;*</span>}
            </Typography.Heading>
          </RadixForm.Label>
        )}
        <div className={inputWrapperClass}>
          {icon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {icon}
            </div>
          )}
          <RadixForm.Control
            type={type}
            className={inputClass}
            disabled={disabled}
            readOnly={readOnly}
            value={inputValue}
            required={required}
            onChange={handleChange}
            placeholder={placeholder}
            ref={ref}
          />
          {inputValue && !readOnly && !disabled && (
            <button
              type="button"
              className={clearButtonClass}
              onClick={handleClear}
            >
              x
            </button>
          )}
        </div>
        {helpText && !error && (
          <Typography.Body size="sub" color="neutral-300" className="mt-2">
            {helpText}
          </Typography.Body>
        )}
        {error && (
          <Typography.Body size="sub" color="brand-orange-300" className="mt-2">
            {error}
          </Typography.Body>
        )}
      </RadixForm.FormField>
    );
  },
);

Input.displayName = "Input";

const memoizedInput = memo(Input);
export { memoizedInput as Input };
